<template>
    <div class="contranctComfirm">
        <div class="title">合同信息确认</div>
        <div class="detail-box">
            <div class="date">
                <div>
                    <span class="head">合同开始时间:</span>
                    <span>2021-09-18</span>
                </div>
                <div>
                    <span class="head">合同结束时间:</span>
                    <span>2021-09-18</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>

</script>
<style lang="scss">
.contranctComfirm {
    padding:40px 24px 0 24px;
    box-sizing: border-box;
    .title {
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
    }
    .detail-box {
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
    }
    .head {
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #8d8d8d;
    }
}
</style>
